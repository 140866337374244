


















import Vue from "vue";
import { List, PullRefresh, Row, Col, Icon } from "vant";

interface ListItem {
  id: number;
  name: string;
  businessName: string;
}
export default Vue.extend({
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon
  },
  data() {
    return {
      list: [] as ListItem[],
      refreshing: false,
      loading: false,
      finished: false,
      error: false
    };
  },
  methods: {
    onRefresh() {
      this.refreshing = true;
      setTimeout(() => {
        const list: ListItem[] = [];
        for (let i = 0; i < 30; i++) {
          list.push({
            id: i,
            name: "a",
            businessName: "b"
          });
        }
        this.list = list;
        this.refreshing = false;
      }, 3000);
    },
    onLoad() {
      this.loading = true;
      const size = this.list.length;
      setTimeout(() => {
        for (let i = 0; i < 40; i++) {
          this.list.push({
            id: i + size,
            name: "a",
            businessName: "b"
          });
        }
        this.loading = false;
      }, 3000);
    }
  }
});
